import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChatAltIcon, IdentificationIcon, ScaleIcon, ViewGridIcon, DocumentIcon } from '@heroicons/react/solid';

import "./assets/nicepage.css";
import "./assets/mainstyle.css";
import imageForum from "../../images/forum.jpg";
import imageID from "../../images/id.jpg";
import imageCourt from "../../images/court.jpg";
import imageFTSO from "../../images/ftso.jpg";
import imageDocs from "../../images/docs.jpg";

const BookPane = ({ title, content, isActive, onClick, bgImage, IconComponent }) => (
  <div
    className={`book-pane ${isActive ? 'active' : ''}`}
    onClick={onClick}
    style={{
      backgroundImage: `url(${bgImage})`,
    }}
  >
    {/* Icon + Title */}
    <div className={`title-container ${isActive ? 'expanded' : ''}`}>
      <IconComponent className="pane-icon" />
      <h2 className="pane-title">{title}</h2>
    </div>

    <div className={`pane-content ${isActive ? 'show' : ''}`}>
      {content}
    </div>
  </div>
);


const Home = () => {
  const [activePaneIndex, setActivePaneIndex] = useState(0)

  const panes = [
    {
      title: "Satraps Forum",
      IconComponent: ChatAltIcon,
      content: (
        <div className="text-white">
          <p className="mb-4">The first on-chain forum on Songbird. Mint a Satrap ID to become a Satrap Citizen on Songbird.</p>
            <a href="https://forum.satraps.io/" className="btn-primary">To Forum</a>
        </div>
      ),
      bgImage: imageForum,
    },
    {
      title: "Satraps ID",
      IconComponent: IdentificationIcon,
      content: (
        <div className="text-white">
          <p className="mb-4">Get your own .satrap ID, customize your domain profile, and use it to engage with other Satraps at the Satraps Forum.</p>
            <a href="https://id.satraps.io/" className="btn-primary">Claim ID</a>
        </div>
      ),
      bgImage: imageID,
    },
    {
      title: "Satraps Court",
      IconComponent: ScaleIcon,
      content: (
        <div className="text-white">
          <p className="mb-4">Satraps on-chain grassroots governance. Help make the web3 a safe and predictable space for real-world businesses.</p>
          <a href="https://beta.satraps.io/" className="btn-primary">Try Closed Beta</a>
        </div>
      ),
      bgImage: imageCourt,
    },
    {
      title: "FTSO Dashboard",
      IconComponent: ViewGridIcon,
      content: (
        <div className="text-white">
          <p className="mb-4">Access the Satraps Ethical FTSO dashboard, where you can delegate to ethical TSOs on the Songbird and the Flare Networks.</p>
          <Link to="/ftso/#/" className="btn-primary">Open Dashboard</Link>
        </div>
      ),
      bgImage: imageFTSO,
    },
    {
      title: "Satrap Docs",
      IconComponent: DocumentIcon,
      content: (
        <div className="text-white">
          <p className="mb-4">Everything you need to know about the Satraps platform. From the Satraps Collection to the Satraps Court and Forum.</p>
          <a href="https://satraps.info" className="btn-primary">Read</a>
        </div>
      ),
      bgImage: imageDocs,
    }
  ]

  const handlePaneClick = (index) => {
    // Only allow pane change if it's not already active
    if (index !== activePaneIndex) {
      setActivePaneIndex(index);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     const script = document.createElement('script');
  //     const script2 = document.createElement('script');
  //     script2.src = '../home/assets/jquery.js';
  //     script.src = '../home/assets/nicepage.js';
  //     window.onload = () => {
  //       document.head.appendChild(script2);
  //       document.head.appendChild(script);
  //     };
  //   }, 1000);
  // }, [])

  return (
    <div className="u-body u-xl-mode">
      <header
        className="u-align-center-xs u-border-3 u-border-no-left u-border-no-right u-border-palette-1-dark-2 u-box-shadow u-header u-image u-shading u-header"
        id="sec-d1f9" data-animation-name="" data-animation-duration="0" data-animation-delay="0"
        data-animation-direction="" data-image-width="4166" data-image-height="2002">
        <div className="u-clearfix u-sheet u-sheet-1">
          <Link to="/"
            className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xl u-align-left-xs u-image u-logo u-image-1"
            data-image-width="1600" data-image-height="350" title="Home" data-animation-name="" data-animation-duration="0"
            data-animation-direction="">
            <img src='/html/images/Logo2withname2.png' className="u-logo-image u-logo-image-1" data-image-width="80" alt="Logo" />
          </Link>
        </div>
      </header>
      <section className="u-align-center u-clearfix u-grey-90 u-section-1">
        <div className="u-clearfix u-sheet u-sheet-1">
          <p className="phead">- The Satraps Collection -
            <span className="sphead">
              The Court of Ground-up Governance
            </span>
          </p>
          <div className="bookcase-wrapper">
            <div className="bookcase-container">
              {panes.map((pane, index) => (
                <BookPane
                  key={index}
                  title={pane.title}
                  content={pane.content}
                  isActive={index === activePaneIndex}
                  onClick={() => handlePaneClick(index)}
                  IconComponent={pane.IconComponent}
                  bgImage={pane.bgImage}
                />
              ))}
            </div>
          </div>


        </div>
      </section>
      <footer className="u-clearfix u-footer u-grey-80" id="sec-95a2">
        <div className="u-clearfix u-sheet u-sheet-1">
          <Link to="/" className="u-image u-logo u-image-1" data-image-width="250" data-image-height="250">
            <img src='/assets/logov1.png' className="u-logo-image u-logo-image-1" alt="Logo" />
          </Link>
          <div className="u-align-left u-social-icons u-spacing-10 u-social-icons-1">
            <a className="u-social-url" target="_blank" rel="noreferrer" data-type="Custom" title="The Satraps Twitter"
              href="https://twitter.com/TheSatraps">
              <span className="u-file-icon u-icon u-social-custom u-social-icon u-icon-3">
                <img src={'/html/images/twitterX1.png'} alt="Twitter" />
              </span>
            </a>
            <a className="u-social-url" data-type="Email" title="Contact us" href="mailto:clavi@satraps.io">
              <img src='/html/images/emaillogo.png' width="23" height="23" alt="Email" />
            </a>
          </div>
        </div>
        <span className="copyright">&copy; 2024 The Satraps Collection. All rights reserved.</span>
      </footer>
    </div>
  )
}

export default Home