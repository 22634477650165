import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import React from "react";
import Home from "./pages/home/Home";
import Ftsoflare from "./pages/ftso-flare/Ftsoflare";
import FtsoIndex from "./pages/ftso/Ftso-Index";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ftso" element={<FtsoIndex />} />
        <Route path="/ftso-flare" element={<Ftsoflare />} />
      </Routes>
      <Analytics />
    </BrowserRouter>
  );
}

export default App;
