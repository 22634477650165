import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
{/* <script type="module" crossorigin src="/assets/index.28c5c0b7.js"></script>
<link rel="modulepreload" href="/assets/vendor.5d4a8e8f.js">
<link rel="stylesheet" href="/assets/index.f5ea000b.css"> */}

const Ftsoflare = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const script = document.createElement('script');
        const linkrel = document.createElement('link');
        const linkrel2 = document.createElement('link');
        const script2 = document.createElement('script');

        script.src = "/assetsflr/index.ed095620.js";
        script.type = "module"
        script.crossOrigin = true
        script.async = true;

        linkrel.rel = "modulepreload"
        linkrel.href = "/assetsflr/vendor.5d4a8e8f.js"

        linkrel2.rel = "stylesheet"
        linkrel2.href = "/assetsflr/index.fc343afd.css"


        document.body.appendChild(script);
        document.body.appendChild(linkrel);
        document.body.appendChild(linkrel2);


        script2.src = "/src2/assetsflr/js/walletConnect.js";
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(linkrel);
            document.body.removeChild(linkrel2);
            document.body.removeChild(script2);
        }
    }, []);


 
const [loading, setLoading] = useState(true)
useEffect(() => {
    setTimeout(() => {
        setLoading(false)
    }, 2000);
}, [])
    return (
        <div >
            <div id="app"></div>
            {loading  && <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: '100%',
                height: "100vh",
                background: "#0b0000",
                zIndex: 20,
                display: 'flex',
                alignItems:"center",
                justifyContent:"center"
            }}>
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
            </div>}
        </div>
    )
}

export default Ftsoflare